/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */
/* 1 */
.ie9 img[src*=".svg"] {
  width: 100%;
}

/* 2 */
@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  img[src*=".svg"] {
    width: 100%;
  }
  input::-ms-clear, textarea::-ms-clear {
    display: none;
  }
  .eq-col-flex {
    display: block;
  }
}
